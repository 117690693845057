<ng-container>
  <div class="{{ className }}__title">VPN Local Configuration</div>
  <div *ngIf="profile?.vpn" class="{{ className }}__subtitle">
    You can find below the IP assigned to your user. You can also download the configuration file
    for your local computer. Please click
    <a
      [target]="'_blank'"
      href="https://knowledge.barbaraiot.com/reference/nodes/configuring-the-vpn/#connecting-to-your-edge-node-from-your-laptop"
      style="color: black; font-weight: bolder; font-family: 'Inter-Bold'"
      >here</a
    >
    for more information.
  </div>
  <table [dataSource]="dataSource" class="{{ className }}__table mat-elevation-z8" mat-table>
    <ng-container matColumnDef="ip">
      <th *matHeaderCellDef mat-header-cell>Ip</th>
      <td *matCellDef="let element" mat-cell>{{ element.ip }}</td>
    </ng-container>

    <ng-container matColumnDef="configFile">
      <th *matHeaderCellDef class="{{ className }}__container-download" mat-header-cell>
        Download Configuration
      </th>
      <td *matCellDef="let element" class="{{ className }}__container-download" mat-cell>
        <button
          (click)="downloadConfig()"
          class="{{ className }}__download"
          color="dark-blue"
          mat-flat-button>
          {{ 'Download' | uppercase }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="nodata">
      <td
        *matFooterCellDef
        [colSpan]="displayedColumns.length"
        class="no-data"
        mat-cell
        mat-footer-row>
        {{ 'No data found' }}
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    <tr *matFooterRowDef="['nodata']" [hidden]="dataSource.length > 0" mat-footer-row></tr>
  </table>
</ng-container>
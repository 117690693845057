export const environment = {
  production: window['env']['envProduction'] || false,
  apiUrl: window['env']['apiUrl'] || 'default',
  analytics_id: window['env']['analytics_id'] || 'default',
  tag_manager: window['env']['tag_manager'] || 'default',
  auth_server: window['env']['auth_server'] || 'default',
  keycloak_realm: window['env']['keycloak_realm'] || 'default',
  panelUrl: window['env']['panelUrl'] || 'default',
  marketUrl: window['env']['marketUrl'] || 'default',
  minOSVersionValid: window['env']['minOSVersionValid'] || '4.2.0',
  academyUrl: window['env']['academyUrl'] || 'default',
  helpdesk: window['env']['helpdesk'] || 'default',
  stratusVirtual: window['env']['stratusVirtual'] || 'default',
  stratusManager: window['env']['stratusManager'] || 'default'
};
